import { runTransaction, userSchedRef, timestampRef } from './firebaseRefs';
import { processStatusChange } from './processStatusChange';
import { getUserDay } from './getUserDay';
import { processCheckInOut } from './processCheckInOut';

export const checkInUser = async (authUser, location, dateRef, type = null) => {
   try {
      const res = await runTransaction(async (t) => {
         const dayDetails = await getUserDay(t, authUser, dateRef);
         if (type !== null) {
            try {
               await processStatusChange(
                  t,
                  authUser,
                  location,
                  dayDetails,
                  dateRef,
                  type,
               );
            } catch (error) {
               console.error('Error processing status change', error);
               throw new Error('Error processing status change');
            }
         }
         try {
            await processCheckInOut(t, authUser, location, dayDetails, dateRef);
         } catch (error) {
            console.error('Error processing check in/out', error);
            throw new Error('Error processing check in/out');
         }
         return `User Check in or out successful`;
      });
      console.log('Transaction success', res);
   } catch (e) {
      console.error('Transaction failure:', e.message);
   }
};
