import { userSchedRef } from './firebaseRefs';

export const getUserDay = async (t, authUser, dateRef) => {
   try {
      const doc = await t.get(userSchedRef(authUser.cid, authUser.uid, dateRef));
      if (doc.exists) {
         return doc.data();
      } else {
         return null;
      }
   } catch (error) {
      console.log(error, 'Error getting user Schedule for the day');
      return { code: 500, message: 'Error Getting User Daily Schedule' };
   }
};
