import {
   deleteFieldRef,
   increment,
   locSchedRef,
   locStatusLevelRef,
   userSchedRef,
} from './firebaseRefs';

export const processStatusChange = async (
   t,
   authUser,
   location,
   dayDetails,
   dateRef,
   type,
) => {
   if (type === 'allday') {
      try {
         processAllDayChange(t, authUser, location, dayDetails, dateRef, type);
      } catch (error) {
         throw new Error('Error processing all day status change');
      }
   } else if (type === 'AM' || type === 'PM') {
      try {
         processHalfDayChange(t, authUser, location, dayDetails, dateRef, type);
      } catch (error) {
         throw new Error('Error processing half day status change');
      }
   }

   //    newStatus = { status: 'split', splitDay: {} };
   //    if (userlid !== location.loc) {
   //       newStatus.offsiteLocation = {};

   //       newStatus.splitDay[type] = 'offsite';
   //       newStatus.offsiteLocation[type] = {
   //          locationId: location.loc,
   //          locationName: location.name,
   //       };
   //    } else {

   //       newStatus.splitDay[type] = 'ito';
   //    }

   //    if (dayDetails == null || dayDetails?.status !== 'split') {

   //       if (type === 'AM') {
   //          newStatus.splitDay.PM = dayDetails.status || '-';
   //          if ((dayDetails.status = 'offsite')) {
   //             newStatus.offsiteLocation = {
   //                PM: { ...dayDetails.offsiteLocation },
   //             };
   //          }
   //       } else {
   //          newStatus.splitDay.PM = dayDetails.status || '-';
   //          if ((dayDetails.status = 'offsite')) {
   //             newStatus.offsiteLocation = {
   //                AM: { ...dayDetails.offsiteLocation },
   //             };
   //          }
   //       }
   //    }
   // }

   // t.set(
   //    locStatusLevelRef(location.loc, dateRef),
   //    { [newStatusObject.status]: increment(1) },
   //    { merge: true },
   // );
   // if (dayDetails.status !== 'split') {
   //    if (type !== 'update') {
   //       t.set(
   //          locStatusLevelRef(location.loc, dateRef),
   //          { [dayDetails.status]: increment(-1) },
   //          { merge: true },
   //       );
   //    }
   // }

   // t.set(
   //    userSchedRef(authUser.cid, authUser.uid, dateRef),
   //    { status, lid: location.loc },
   //    { merge: true },
   // );
   // t.set(locSchedRef(location.loc, dateRef), { [authUser.uid]: { status } }, { merge: true });
};

function processAllDayChange(t, authUser, location, dayDetails, dateRef, type) {
   if (dayDetails.status === 'split') {
      let offsiteExists = false;
      Object.entries(dayDetails.splitDay).forEach(([key, value]) => {
         if (value === 'offsite') {
            offsiteExists = true;
         }
         t.set(
            locStatusLevelRef(authUser.lid, dateRef),
            { [key]: { [value]: increment(-1) } },
            { merge: true },
         );
      });
      let fieldsToDelete = {
         splitDay: deleteFieldRef,
      };
      if (offsiteExists) {
         fieldsToDelete.offsiteLocation = deleteFieldRef;
      }
      t.set(userSchedRef(authUser.cid, authUser.uid, dateRef), fieldsToDelete, {
         merge: true,
      });
      t.set(
         locSchedRef(location.loc, dateRef),
         { [authUser.uid]: fieldsToDelete },
         { merge: true },
      );
   } else {
      t.set(
         locStatusLevelRef(authUser.lid, dateRef),
         { [dayDetails.status]: increment(-1) },
         { merge: true },
      );
   }
   if (dayDetails.status === 'offsite') {
      t.set(
         userSchedRef(authUser.cid, authUser.uid, dateRef),
         { offsiteLocation: deleteFieldRef },
         { merge: true },
      );
      t.set(
         locSchedRef(location.loc, dateRef),
         { [authUser.uid]: { offsiteLocation: deleteFieldRef } },
         { merge: true },
      );
   }
   let newStatus = { status: 'ito' };
   if (authUser.lid[0] !== location.loc) {
      newStatus = {
         status: 'offsite',
         offsiteLocation: {
            locationId: location.loc,
            locationName: location.locName,
         },
      };
   }
   t.set(
      locStatusLevelRef(authUser.lid[0], dateRef),
      { [newStatus.status]: increment(1) },
      { merge: true },
   );
   t.set(
      userSchedRef(authUser.cid, authUser.uid, dateRef),
      { ...newStatus, lid: authUser.lid[0] },
      { merge: true },
   );
   t.set(
      locSchedRef(authUser.lid[0], dateRef),
      { [authUser.uid]: { ...newStatus, lid: authUser.lid[0] } },
      { merge: true },
   );
}

const processHalfDayChange = (t, authUser, location, dayDetails, dateRef, type) => {
   if (dayDetails.status === 'split') {
      t.set(
         locStatusLevelRef(authUser.lid, dateRef),
         { [type]: { [dayDetails.splitDay[type]]: increment(-1) } },
         { merge: true },
      );
      if (dayDetails.splitDay[type] === 'offsite') {
         t.set(
            locSchedRef(authUser.lid, dateRef),
            { [authUser.uid]: { offsiteLocation: { [type]: deleteFieldRef } } },
            { merge: true },
         );
         t.set(
            userSchedRef(authUser.cid, authUser.uid, dateRef),
            { offsiteLocation: { [type]: deleteFieldRef } },
            { merge: true },
         );
      }
      let newStatus = { splitDay: { [type]: 'ito' } };
      if (authUser.lid[0] !== location.loc) {
         newStatus = {
            splitDay: { [type]: 'offsite' },
            offsiteLocation: {
               [type]: { locationId: location.loc, locationName: location.locName },
            },
         };
      }
      t.set(
         locStatusLevelRef(authUser.lid[0], dateRef),
         { [type]: { [newStatus.splitDay[type]]: increment(1) } },
         { merge: true },
      );
      t.set(
         userSchedRef(authUser.cid, authUser.uid, dateRef),
         { ...newStatus, lid: authUser.lid[0] },
         { merge: true },
      );
      t.set(
         locSchedRef(authUser.lid[0], dateRef),
         { [authUser.uid]: { ...newStatus, lid: authUser.lid[0] } },
         { merge: true },
      );
   } else {
      t.set(
         locStatusLevelRef(authUser.lid, dateRef),
         { [dayDetails.status]: increment(-1) },
         { merge: true },
      );
      let newStatus = { status: 'split', splitDay: { [type]: 'ito' } };
      if (authUser.lid[0] !== location.loc) {
         newStatus = {
            status: 'split',
            splitDay: { [type]: 'offsite' },
            offsiteLocation: {
               [type]: { locationId: location.loc, locationName: location.locName },
            },
         };
      }
      if (type === 'AM') {
         newStatus.splitDay.PM = dayDetails.status;
         if (dayDetails.status === 'offsite') {
            newStatus.offsiteLocation = { PM: { ...dayDetails.offsiteLocation } };
         }
      } else {
         newStatus.splitDay.AM = dayDetails.status;
         if (dayDetails.status === 'offsite') {
            newStatus.offsiteLocation = { AM: { ...dayDetails.offsiteLocation } };
         }
      }
      t.set(
         locStatusLevelRef(authUser.lid[0], dateRef),
         {
            AM: { [newStatus.splitDay.AM]: increment(1) },
            PM: { [newStatus.splitDay.PM]: increment(1) },
         },
         { merge: true },
      );
      t.set(
         userSchedRef(authUser.cid, authUser.uid, dateRef),
         { ...newStatus, lid: authUser.lid[0] },
         { merge: true },
      );
      t.set(
         locSchedRef(authUser.lid[0], dateRef),
         { [authUser.uid]: { ...newStatus, lid: authUser.lid[0] } },
         { merge: true },
      );
   }
};

// async function statusClick(row, props, value) {
//    async function getStatus(value) {
//       switch (value) {
//          case 'away':
//             return '-';
//          case 'ito':
//             return 'wfh';
//          case 'wfh':
//             if (props.location.offsiteEnabled) {
//                return 'offsite';
//             } else {
//                return 'away';
//             }

//          case 'offsite':
//             return 'away';
//          case '-':
//             return 'ito';
//          default:
//             return '-';
//       }
//    }
//    if (row.status[props.dayNo].set === 'split' || props.miniChips) {
//       props.setStatusDrawerOpen(true);
//       props.setSpaceData(data);
//    } else if (
//       props.row.offsiteLocation[props.dayNo] !== '' &&
//       props.row.offsiteLocation[props.dayNo] !== null
//    ) {
//       setChipModal({ open: true });
//       setClickable(true);
//    } else if (props.row.space[props.dayNo] !== 'empty') {
//       setChipModal({ open: true });
//       setClickable(true);
//    } else {
//       setClickable(false);
//       var oldStatus = value;
//       var status = await getStatus(value);
//       let routine = false;
//       let oldLocation = '';
//       if (props.row.status[props.dayNo].lid) {
//          oldLocation = props.row.status[props.dayNo].lid;
//       } else {
//          oldLocation = props.row.lid;
//       }
//       if (props.row.status[props.dayNo].originalStatus) {
//          oldStatus = props.row.status[props.dayNo].originalStatus;
//       }
//       let locations = {
//          old: oldLocation,
//          new: props.row.lid,
//       };
//       if (props.row.status[props.dayNo].routine && props.date.ref > today.ref) {
//          routine = {
//             status: props.row.status[props.dayNo].routine,
//             overridden: true,
//             dayNo: props.date.weekDay,
//          };
//          if (status === props.row.status[props.dayNo].routine) {
//             routine.overridden = false;
//          }
//       }
//       try {
//          await props.firebase.setStatus(
//             user,
//             date,
//             status,
//             oldStatus,
//             locations,
//             routine,
//             authUser,
//          );
//       } catch (error) {
//          console.warn(error);
//       }
//    }
// }

// setStatus = async (
//    user,
//    date,
//    status,
//    oldStatus,
//    locations,
//    routine,
//    authUser,
//    drawer = false,
// ) => {
//    const batch = this.db.batch();
//    const increment = firebase.firestore.FieldValue.increment(1);
//    const decrement = firebase.firestore.FieldValue.increment(-1);
//    const deleteField = firebase.firestore.FieldValue.delete();
//    const instructions = this.db.doc(`location/${locations.new}`);
//    batch.set(instructions, { setup: { status: true } }, { merge: true });

//    const statusLevelOld = this.db
//       .collection(`location/${locations.old}/statusLevels/`)
//       .doc(`${date}`);
//    const statusLevelNew = this.db
//       .collection(`location/${locations.new}/statusLevels/`)
//       .doc(`${date}`);
//    let sl = { [status]: increment, [oldStatus]: decrement };
//    let slOld = { [oldStatus]: decrement };
//    let slNew = { [status]: increment };

//    const locationStatusOld = this.db
//       .collection(`location/${locations.old}/schedule`)
//       .doc(`${date}`);
//    const locationStatusNew = this.db
//       .collection(`location/${locations.new}/schedule`)
//       .doc(`${date}`);
//    let lsOld = { [user.uid]: { status: deleteField } };
//    let lsNew = { [user.uid]: { status } };

//    const personStatus = this.db
//       .collection(`company/${user.cid}/people/${user.uid}/schedule`)
//       .doc(`${date}`);
//    let ps = { status: status, lid: locations.new };

//    if (routine && routine.status !== '-') {
//       if (!routine.overridden) {
//          slNew = { overridden: { [routine.status]: decrement } };
//          lsNew[user.uid] = { ...lsNew[user.uid], overridden: false };
//          ps = { ...ps, overridden: false };
//          batch.set(personStatus, ps, { merge: true });
//       } else if (routine.overridden && oldStatus === routine.status) {
//          slOld = false;
//          slNew = { [status]: increment, overridden: { [routine.status]: increment } };
//          lsNew[user.uid] = {
//             ...lsNew[user.uid],
//             overridden: true,
//             routine: routine.status,
//          };
//          ps = { ...ps, overridden: true, routine: routine.status };
//          batch.set(personStatus, ps, { merge: true });
//       } else {
//          lsNew[user.uid] = {
//             ...lsNew[user.uid],
//             overridden: true,
//             routine: routine.status,
//          };
//          batch.set(personStatus, ps, { merge: true });
//       }
//    } else {
//       batch.set(personStatus, ps, { merge: true });
//    }
//    if (slOld) {
//       batch.set(statusLevelOld, slOld, { merge: true });
//    }
//    batch.set(statusLevelNew, slNew, { merge: true });
//    batch.set(locationStatusOld, lsOld, { merge: true });
//    batch.set(locationStatusNew, lsNew, { merge: true });

//    useSetStatusLogging(batch, user, date, status, authUser, drawer);
//    batch.commit();
// };
