import firebase from 'firebase/app';

export const runTransaction = (transactionFunction) =>
   firebase.firestore().runTransaction(transactionFunction);
export const increment = (i) => firebase.firestore.FieldValue.increment(i);
export const timestampRef = firebase.firestore.FieldValue.serverTimestamp();
export const deleteFieldRef = firebase.firestore.FieldValue.delete();
export const arrayUnionRef = (unionData) =>
   firebase.firestore.FieldValue.arrayUnion(unionData);

export const userSchedRef = (cid, uid, dateRef) =>
   firebase.firestore().doc(`company/${cid}/people/${uid}/schedule/${dateRef}`);
export const locSchedRef = (lid, dateRef) =>
   firebase.firestore().collection(`location/${lid}/schedule`).doc(`${dateRef}`);
export const locStatusLevelRef = (lid, dateRef) =>
   firebase.firestore().doc(`location/${lid}/statusLevels/${dateRef}`);
