import { createContext, useContext, useEffect, useState } from 'react';
import { withFirebase } from '../components/Firebase';
import firebase from 'firebase';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { UserLog } from '../constants/logs';
import Loading from '../components/Loading';
import { Box } from '@mui/material';

const contextName = 'Auth';
const AuthContext = createContext(undefined);

const AuthProvider = (props) => {
   const { children } = props;
   const history = useHistory();
   const [authUser, setAuthUser] = useState(null);
   const [authLoading, setAuthLoading] = useState(true);
   const storedUser = JSON.parse(localStorage.getItem('authUser'));
   const pageLoc = useLocation();

   useEffect(() => {
      if (!authUser && storedUser !== null) {
         props.firebase.user(storedUser.uid).onSnapshot(
            (result) => {
               setAuthUser({
                  id: result.id,
                  uid: result.id,
                  profileRoles: result.data().profileRoles || [],
                  ...result.data(),
               });
               setAuthLoading(false);
            },
            (error) => {
               // TODO: we need to take care of this scenario
               console.log(error, 'error');
               setAuthLoading(false);
            },
         );
      } else {
         history.push('/signin');
      }
   }, []);

   useEffect(() => {
      if (!authLoading) {
         UserLog(props.firebase, authUser, pageLoc);
      }
   }, [pageLoc, authLoading]);

   return (
      <AuthContext.Provider
         value={{
            authUser,
            authLoading,
         }}
      >
         {authLoading ? (
            <Box
               display="flex"
               justifyContent="center"
               alignItems="center"
               height="100vh"
            >
               <Loading />
            </Box>
         ) : (
            children
         )}
      </AuthContext.Provider>
   );
};

export const useAuth = () => {
   const context = useContext(AuthContext);

   if (!context) {
      throw new Error(
         `You are trying to use this hook outside the ${contextName} provider`,
      );
   }

   return context;
};

export default withFirebase(AuthProvider);
