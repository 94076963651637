import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import { DateToday } from '../../constants/functions';
import * as ROUTES from '../../constants/routes';
import { green } from '@material-ui/core/colors';
import { AuthUserContext } from '../Session';
import { useAuth } from '../../providers';
import NoBooking from './components/noBooking';
import { processCheckInOut } from './functions/processCheckInOut';
import { runTransaction } from './functions/firebaseRefs';

const useStyles = makeStyles((theme) => ({
   container: {
      height: '70vh',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: theme.spacing(8),
   },
   paper: {
      padding: theme.spacing(2),
   },
   green: {
      color: green[700],
   },
}));

const ProcessBox = (props) => {
   const { authUser } = useAuth();
   var today = DateToday();

   async function handleCheckIn() {
      props.setStatus('loading');
      const res = await runTransaction(async (t) => {
         try {
            await processCheckInOut(
               t,
               authUser,
               props.location,
               props.checkInData.details,
               today.ref,
            );
         } catch (error) {
            console.error('Error processing check in/out', error);
            return { code: 500, message: 'Error processing check in/out' };
         }
         return `User Check in or out successful`;
      });
      props.setStatus('checkedIn');
      return res;
   }
   return (
      <React.Fragment>
         <Typography
            component="h1"
            variant="h2"
            color={props.error ? 'secondary' : 'primary'}
         >
            {props.title}
         </Typography>
         {props.messages.map((message, key) => (
            <Typography key={key} variant="body1" paragraph={true}>
               {message}
            </Typography>
         ))}
         {props.button == 'go to Schedule' && (
            <Button variant="contained" color="primary" href={ROUTES.SCHEDULE}>
               {props.button}
            </Button>
         )}
         {props.button == 'Submit' && (
            <Button
               variant="contained"
               color="primary"
               onClick={() => {
                  handleCheckIn(props.firebase, props.checkInData, props.setStatus);
               }}
            >
               {props.button}
            </Button>
         )}
      </React.Fragment>
   );
};

const LoggedIn = (props) => {
   const classes = useStyles();

   const Loading = () => {
      return (
         <Paper className={classes.paper}>
            <CircularProgress />
         </Paper>
      );
   };

   const CheckIn = () => {
      const { authUser } = useAuth();
      const localUser = JSON.parse(localStorage.getItem('authUser'));
      var today = DateToday();
      const [status, setStatus] = useState('');
      const [checkOut, setCheckOut] = useState('in');
      const [checkInData, setCheckInData] = useState(false);
      const [day, setDay] = useState({ status: '-' });
      useEffect(() => {
         const unsubscribe = async () => {
            try {
               const result = await props.firebase
                  .userScheduleDay(authUser.cid, authUser.uid, today.ref)
                  .get();
               if (result.exists) {
                  let data = result.data();
                  setDay(data);
                  if (
                     data.checkedIn &&
                     !data.checkedOut &&
                     data.checkedInTo === props.location.loc
                  ) {
                     setCheckOut('out');
                  }
                  setCheckInData({
                     uid: props.user.uid,
                     lid: props.location.loc,
                     date: today,
                     cid: props.user.cid,
                     details: data,
                  });
                  if (
                     (data.status === 'ito' ||
                        (data.status === 'split' &&
                           (data.splitDay.AM === 'ito' || data.splitDay.PM === 'ito'))) &&
                     data.lid === props.location.loc
                  ) {
                     setStatus('submitForm');
                  } else if (
                     data.status === 'split' &&
                     ((data.splitDay.AM === 'offsite' &&
                        data.offsiteLocation.AM.locationId === props.location.loc) ||
                        (data.splitDay.PM === 'offsite' &&
                           data.offsiteLocation.PM.locationId === props.location.loc))
                  ) {
                     setStatus('submitForm');
                  } else if (
                     data.status === 'offsite' &&
                     data.offsiteLocation?.locationId === props.location.loc
                  ) {
                     setStatus('submitForm');
                  } else {
                     setStatus('noBooking');
                  }
               } else {
                  setStatus('noBooking');
               }
            } catch (error) {
               console.warn(error, 'Error getting user Schedule for the day');
               setStatus('noLocation');
            }
         };
         return unsubscribe();
      }, []);

      var title = '';
      var messages = [];
      var button = null;
      var error = false;
      switch (status) {
         case 'submitForm':
            title = 'Hi ' + localUser.name.firstName + ' ' + localUser.name.lastName;
            messages = [
               `Click Submit to complete your check ${checkOut} at ${props.location.locName}`,
            ];
            button = 'Submit';

            return (
               <Paper className={classes.paper}>
                  <ProcessBox
                     title={title}
                     messages={messages}
                     button={button}
                     error={error}
                     checkInData={checkInData}
                     setStatus={setStatus}
                     location={props.location}
                  />
               </Paper>
            );
         case 'noBooking':
            return (
               <NoBooking
                  day={day}
                  location={props.location}
                  checkOut={checkOut}
                  setStatus={setStatus}
               />
            );
         case 'noLocation':
            title = 'Oops, something went wrong';
            error = true;
            messages = [
               "We couldn't find the location you're trying to check into.",
               'Please try to scan the QR code again.',
               'If you keep having problems, please contact your administrator.',
            ];
            return (
               <Paper className={classes.paper}>
                  <ProcessBox
                     title={title}
                     messages={messages}
                     button={button}
                     error={error}
                  />
               </Paper>
            );
         case 'checkedIn':
            return (
               <Paper className={classes.paper}>
                  <CheckCircle className={classes.green} fontSize="large" />
                  <Typography className={classes.green} component="h1" variant="h2">
                     All done.
                  </Typography>
                  <Typography variant="body1">
                     Thank you for checking {checkOut}. You can now close this window.
                  </Typography>
               </Paper>
            );
         default:
            return <CircularProgress />;
      }
   };

   return (
      <React.Fragment>
         <div className={classes.container}>
            <Container>
               <AuthUserContext.Consumer>
                  {(authUser) => (authUser ? <CheckIn /> : <Loading />)}
               </AuthUserContext.Consumer>
            </Container>
         </div>
      </React.Fragment>
   );
};

export default withFirebase(LoggedIn);
