import { Done, PinDrop } from '@mui/icons-material';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';
import NonClickableStatus from './nonClickableChips';
import OffsiteLocationDetails from './offsiteLocationDetails';

import { useAuth, useCompany } from '../../../providers';
import { checkInUser } from '../functions/checkInUser';
import { DateToday } from '../../../constants/functions';
import { useState } from 'react';

const NoBooking = ({ day, location, checkOut, setStatus }) => {
   const { company } = useCompany();
   const { authUser } = useAuth();
   const today = DateToday();

   async function handleClick(type) {
      setStatus('loading');
      try {
         await checkInUser(authUser, location, today.ref, type);
         setStatus('checkedIn');
      } catch (error) {
         console.error('Error checking in - ', error);
      }
   }

   return (
      <Box
         component={Paper}
         sx={{
            p: 3,
            textAlign: 'left',
            maxWidth: '600px',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
         }}
      >
         <Typography
            component="h1"
            variant="h4"
            color="secondary"
            align="center"
            sx={{ mb: 2 }}
         >
            Just One More Step
         </Typography>
         <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width="100%"
         >
            <Box sx={{ mb: 2 }}>
               {day.status === 'split' ? (
                  <Typography variant="body1">
                     Your status is set to be - AM:{' '}
                     <NonClickableStatus status={day.splitDay.AM} />
                     {''}
                     <OffsiteLocationDetails
                        status={day.splitDay.AM}
                        location={day.offsiteLocation.AM || null}
                     />
                     , PM: <NonClickableStatus status={day.splitDay.PM} />{' '}
                     <OffsiteLocationDetails
                        status={day.splitDay.PM}
                        location={day.offsiteLocation.PM || null}
                     />
                     but you are trying to check {checkOut} at {location.locName}.
                  </Typography>
               ) : (
                  <Typography variant="body1">
                     Your status is set to be <NonClickableStatus status={day.status} />{' '}
                     <OffsiteLocationDetails
                        status={day.status}
                        location={day.offsiteLocation || null}
                     />
                     but you are trying to check {checkOut} at {location.locName}.
                  </Typography>
               )}
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Box>
               <Typography variant="body1" paragraph>
                  Please select one of the below options to continue.
               </Typography>
               <Box sx={{ width: '100%' }}>
                  <Button
                     onClick={() => handleClick('allday')}
                     sx={{ mb: 2 }}
                     variant="contained"
                     fullWidth
                     startIcon={day.status === 'ito' ? <PinDrop /> : <Done />}
                     endIcon={day.status === 'ito' ? <PinDrop /> : <Done />}
                  >
                     Update your status
                  </Button>
                  {company.features?.advancedScheduling && (
                     <Box
                        sx={{
                           display: 'flex',
                           flexDirection: { xs: 'column', sm: 'row' },
                           justifyContent: 'space-between',
                           gap: 2,
                           mb: 2,
                        }}
                     >
                        <Button
                           onClick={() => handleClick('AM')}
                           variant="outlined"
                           startIcon={day.status === 'ito' ? <PinDrop /> : <Done />}
                           sx={{ width: { xs: '100%', sm: 'auto' } }}
                        >
                           Change your AM status
                        </Button>
                        <Button
                           onClick={() => handleClick('PM')}
                           variant="outlined"
                           endIcon={day.status === 'ito' ? <PinDrop /> : <Done />}
                           sx={{ width: { xs: '100%', sm: 'auto' } }}
                        >
                           Change your PM status
                        </Button>
                     </Box>
                  )}
                  <Button
                     onClick={() => handleClick(null)}
                     sx={{ mb: 2, borderColor: 'grey.500' }}
                     fullWidth
                     variant="outlined"
                  >
                     Keep existing status and check {checkOut} anyway
                  </Button>
               </Box>
            </Box>
         </Box>
      </Box>
   );
};

export default NoBooking;
