import React from 'react';
import { Typography } from '@mui/material';

const OffsiteLocationDetails = ({ status, location }) => {
   if (status === 'offsite' && location !== null) {
      return (
         <Typography variant="span" sx={{ fontStyle: 'italic' }}>
            {' '}
            ({location.locationName}){' '}
         </Typography>
      );
   } else {
      return null;
   }
};

export default OffsiteLocationDetails;
