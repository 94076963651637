import React from 'react';
import { Box, Chip } from '@mui/material';
import { Done, DoNotDisturbOn, HomeOutlined, PinDrop } from '@mui/icons-material';

const NonClickableStatus = ({ status }) => {
   switch (status) {
      case 'ito':
         return (
            <Chip label={<Done sx={{ mt: 0.5 }} />} variant="outlined" color="primary" />
         );
      case 'wfh':
         return (
            <Chip
               label={<HomeOutlined sx={{ mt: 0.5 }} />}
               variant="outlined"
               color="primary"
            />
         );
      case 'offsite':
         return (
            <Chip
               label={<PinDrop sx={{ mt: 0.5 }} />}
               variant="outlined"
               color="primary"
            />
         );
      case 'away':
         return (
            <Chip
               label={<DoNotDisturbOn sx={{ mt: 0.5 }} />}
               variant="outlined"
               color="error"
            />
         );
      case '-':
         return <Chip label="-" variant="outlined" />;
      default:
         return <Chip label="-" variant="outlined" />;
   }
};

export default NonClickableStatus;
