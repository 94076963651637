import { userSchedRef, locSchedRef, arrayUnionRef, deleteFieldRef } from './firebaseRefs';

export const processCheckInOut = async (t, authUser, location, dayDetails, dateRef) => {
   let userData = {
      checkedIn: true,
      checkedInTime: new Date(),
      checkedInTo: location.loc,
   };
   let locationData = {
      [authUser.uid]: {
         checkedIn: true,
         checkedInTime: new Date(),
      },
   };
   let deletedFields = null;
   if (dayDetails.checkedOut) {
      userData = {
         checkedIn: true,
         checkedInTime: new Date(),
         checkedInTo: location.loc,
      };
      deletedFields = {
         checkedOut: deleteFieldRef,
         checkedOutTime: deleteFieldRef,
         checkedOutTo: deleteFieldRef,
      };
      locationData[authUser.uid] = {
         checkedIn: true,
         checkedInTime: new Date(),
      };
      if (dayDetails.checkedOutTo === location.loc) {
         locationData[authUser.uid].checkedOut = deleteFieldRef;
         locationData[authUser.uid].checkedOutTime = deleteFieldRef;
      }
   } else if (dayDetails.checkedIn) {
      if (dayDetails.checkedInTo !== location.loc) {
         t.set(
            locSchedRef(dayDetails.checkedInTo, dateRef),
            {
               [authUser.uid]: {
                  checkedOut: true,
                  checkedOutTime: new Date(),
               },
            },
            { merge: true },
         );
         t.set(
            userSchedRef(authUser.cid, authUser.uid, dateRef),
            {
               checkInOutLog: arrayUnionRef({
                  checkedOut: true,
                  checkedOutTime: new Date(),
                  checkedOutTo: dayDetails.checkedInTo,
               }),
            },
            { merge: true },
         );
         userData = {
            checkedIn: true,
            checkedInTime: new Date(),
            checkedInTo: location.loc,
         };
         locationData[authUser.uid] = {
            checkedIn: true,
            checkedInTime: new Date(),
            checkedOut: deleteFieldRef,
            checkedOutTime: deleteFieldRef,
         };
      } else {
         userData = {
            checkedOut: true,
            checkedOutTime: new Date(),
            checkedOutTo: location.loc,
         };
         locationData[authUser.uid] = {
            checkedOut: true,
            checkedOutTime: new Date(),
         };
      }
   }
   t.set(
      userSchedRef(authUser.cid, authUser.uid, dateRef),
      {
         checkInOutLog: arrayUnionRef(userData),
         ...userData,
      },
      { merge: true },
   );
   t.set(locSchedRef(location.loc, dateRef), locationData, { merge: true });
   if (deletedFields !== null) {
      t.set(userSchedRef(authUser.cid, authUser.uid, dateRef), deletedFields, {
         merge: true,
      });
   }
};
