import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import NotLoggedIn from './notLoggedIn';
import LoggedIn from './loggedIn';
import Loading from './loading';
import queryString from 'query-string';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { AuthProvider, CompanyProvider } from '../../providers';
import { Button } from '@mui/material';
import { migrateLids } from './migrateLids';
import { checkBrokenGroups } from './checkBrokenGroups';

const ScanPageDisplay = (props) => {
   switch (props.scanInStatus) {
      case 'notLoggedIn':
         return <NotLoggedIn setScanInStatus={props.setScanInStatus} />;
      case 'loggedIn':
         return <LoggedIn user={props.user} location={props.location} />;
      default:
         return <Loading />;
   }
};

const ScanIn = (props) => {
   const user = JSON.parse(localStorage.getItem('authUser'));
   let url = queryString.parse(window.location.search);
   const [location, setLocation] = useState({});
   const [scanInStatus, setScanInStatus] = useState('loading');

   useEffect(() => {
      if (url.locName && url.loc) {
         setLocation(url);
         if (user || url.loggedIn) {
            setScanInStatus('loggedIn');
         } else {
            setScanInStatus('notLoggedIn');
         }
      } else {
         props.history.push(ROUTES.PERMISSIONDENIED);
      }
   }, [scanInStatus]);

   return (
      <AuthProvider>
         <CompanyProvider>
            <ScanPageDisplay
               user={user}
               scanInStatus={scanInStatus}
               setScanInStatus={setScanInStatus}
               location={location}
            />
            {/* <Button onClick={() => migrateLids()}>Migrate LIDs</Button>
            <Button onClick={() => checkBrokenGroups()}>Check broken groups</Button> */}
         </CompanyProvider>
      </AuthProvider>
   );
};

const ScanInForm = compose(withRouter, withFirebase)(ScanIn);

export default ScanInForm;
